import React, { useState, useEffect } from "react";
import Container from "./layout/Container";
import "../style/rangeSlider.css";
import { RangeSlider } from "rsuite";
import NoData from "./NoData";
import $ from "jquery";

export default function Search({ data }) {
  let [spaces] = useState(data.allContentfulTheOfficeSpace.nodes);
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [isLoading, setLoadToggle] = useState(false);
  const [sortType, setSortType] = useState("");

  const getMaxPrice = arr => {
    let max = 0;
    arr.forEach(space => {
      if (max < space.prix) {
        max = space.prix;
      }
    });
    return max;
  };

  const [sliderValue, setSliderValue] = React.useState([
    0,
    getMaxPrice(spaces),
  ]);

  const handleSearchInputChange = event => {
    setLoadToggle(true);
    setQuery(event.target.value);
  };
  const handleLocationChange = event => {
    setLoadToggle(true);
    setLocation(event.target.value);
  };
  const handleSortType = e => {
    setSortType(e.target.value);
  };
  /* Debounced state values */
  const debouncedQuery = useDebouncedValue(query, 800);
  const debouncedLocation = useDebouncedValue(location, 800);
  const debouncedSliderMinValue = useDebouncedValue(sliderValue[0], 800);
  const debouncedSliderMaxValue = useDebouncedValue(sliderValue[1], 800);
  const debouncedSortType = useDebouncedValue(sortType, 800);

  /* Search fliter */
  const filteredSpaces = spaces
    .filter(name =>
      name.spaceName.toLowerCase().includes(debouncedQuery.toLowerCase())
    )
    .filter(space => {
      setTimeout(() => {
        isLoading && setLoadToggle(false);
      }, 2000);
      return space.stateAddress
        ?.toLowerCase()
        .includes(debouncedLocation.toLowerCase());
    })
    .filter(
      space =>
        space.prix >= debouncedSliderMinValue &&
        debouncedSliderMaxValue >= space.prix
    )
    .sort((a, b) => {
      if (debouncedSortType === "high") {
        return b.prix - a.prix;
      } else if (debouncedSortType === "low") {
        return a.prix - b.prix;
      }
    });

  useEffect(() => {
    // set the height of the blank div to the
    //same height of the search control div
    document.querySelector("#blank-div").style.height =
      document.querySelector(".search-control").offsetHeight + "px";
    // mange the search control bar depon on the scroll position
    let searchBar = $(".search-control");
    let distance = searchBar.offset().top;
    if ($(window).width() > 425) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= distance) {
          searchBar.addClass("search-control__fixed");
          $("#blank-div").removeClass("force-hidden");
        } else if ($(window).scrollTop() < distance) {
          searchBar.removeClass("search-control__fixed");
          $("#blank-div").addClass("force-hidden");
        }
      });
    }
  }, []);
  return (
    <section id="search">
      <div className="search-control">
        <div className="search-control__name">
          <label htmlFor="name">Nom d'espace</label>
          <input
            type="text"
            placeholder="ex: Opéra d'alger"
            className="search-control__spaceTitle"
            id="name"
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="search-control__location">
          <label htmlFor="location">La commune</label>
          <select onChange={handleLocationChange} name="location" className="">
            <option value="">Toute Les Willaya</option>
            <option value="Adrar">Adrar</option>
            <option value="Chelf">Chelf</option>
            <option value="Laghouat">Laghouat</option>
            <option value="Oum el-Bouaghi">Oum el-Bouaghi</option>
            <option value="Batna">Batna</option>
            <option value="Béjaïa">Béjaïa</option>
            <option value="Biskra">Biskra</option>
            <option value="Béchar">Béchar</option>
            <option value="Blida">Blida</option>
            <option value="Bouira">Bouira</option>
            <option value="Tamanghasset">Tamanghasset</option>
            <option value="Tébessa">Tébessa</option>
            <option value="Tlemcen">Tlemcen</option>
            <option value="Tiaret">Tiaret</option>
            <option value="Tizi Ouzou">Tizi Ouzou</option>
            <option value="Alger">Alger</option>
            <option value="Djelfa">Djelfa</option>
            <option value="Jijel">Jijel</option>
            <option value="Sétif">Sétif</option>
            <option value="Saïda">Saïda</option>
            <option value="Skikda">Skikda</option>
            <option value="Sidi Bel Abbes">Sidi Bel Abbes</option>
            <option value="Annaba">Annaba</option>
            <option value="Guelma">Guelma</option>
            <option value="Constantine">Constantine</option>
            <option value="Médéa">Médéa</option>
            <option value="Mostaganem">Mostaganem</option>
            <option value="M'sila">M'sila</option>
            <option value="Mascara">Mascara</option>
            <option value="Ouargla">Ouargla</option>
            <option value="Oran">Oran</option>
            <option value="El Bayadh">El Bayadh</option>
            <option value="Illizi">Illizi</option>
            <option value="Bordj Bou Arréridj">Bordj Bou Arréridj</option>
            <option value="Boumerdès">Boumerdès</option>
            <option value="El Tarf">El Tarf</option>
            <option value="Tindouf">Tindouf</option>
            <option value="Tissemsilt">Tissemsilt</option>
            <option value="El Oued">El Oued</option>
            <option value="Khenchela">Khenchela</option>
            <option value="Souk Ahras">Souk Ahras</option>
            <option value="Tipaza">Tipaza</option>
            <option value="Mila">Mila</option>
            <option value="Aïn Defla">Aïn Defla</option>
            <option value="Naâma">Naâma</option>
            <option value="Aïn Témouchent">Aïn Témouchent</option>
            <option value="Ghardaïa">Ghardaïa</option>
            <option value="Relizane">Relizane</option>
          </select>
        </div>
      </div>
      <div className="force-hidden" id="blank-div"></div>
      <Container>
        <div className="search-result__number">
          <select onChange={handleSortType}>
            <option value="">Trié Par</option>
            <option value="high">le prix le plus élevé</option>
            <option value="low">le prix le plus bas</option>
          </select>
          <span>
            <strong>{filteredSpaces.length ?? "0"}</strong> espace trouvé
          </span>
        </div>
        <div className="search-result">
          <div className="filter">
            <p>Filter</p>
            <div className="filter-warper">
              <label htmlFor="rangeSlider">Le prix:</label>
              <>
                <RangeSlider
                  progress
                  name="rangeSlider"
                  value={sliderValue}
                  max={Number(getMaxPrice(spaces))}
                  min={Number(0)}
                  onChange={value => {
                    setSliderValue(value);
                  }}
                />
                <div className="value-indicators">
                  <span>{sliderValue[0]} DA</span>
                  <span>{sliderValue[1]} DA</span>
                </div>
              </>
            </div>
          </div>
          <div className={`space-result${isLoading ? " isLaoding" : ""}`}>
            {filteredSpaces.length !== 0 ? (
              filteredSpaces.map(space => {
                return (
                  <div className="space" key={space.slug}>
                    <img
                      src={space.spaceCoverPicture.file.url}
                      alt=""
                      className="space-image"
                    />
                    <div className="space-info">
                      <div className="space-detail">
                        <h3 className="space-title">{space.spaceName}</h3>
                        <p className="space-descrption">
                          {space.spaceDescription.spaceDescription.substring(
                            0,
                            100
                          ) + "..."}
                        </p>
                      </div>
                      <div className="space-cta">
                        <div className="space-price">
                          <span>a partir de</span>
                          <p className="price">{space.prix} DA</p>
                          <span>la journée</span>
                        </div>
                        <a href={space.slug} target="_blank" rel="noreferrer">
                          <button className="price-cta">je découvere</button>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
}
function useDebouncedValue(value, wait) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(id);
  }, [value, wait]);

  return debouncedValue;
}
