import React from "react";

export default function NoData() {
  return (
    <div style={{ textAlign: "center" }} className="no_data">
      <img style={{ width: "25%" }} src="/img/No_data.svg" />
      <h4
        style={{
          textTransform: "capitalize",
          color: "#676767",
          margin: "1em auto",
        }}
      >
        aucun éspace trouvé !
      </h4>
    </div>
  );
}
