import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/Hero";
import Footer from "../components/layout/Footer";
import Search from "../components/search";
export default function findSpacePage({ data }) {
  const heroContent = {
    title: "Trouver votre espace EN TOUTE SIMPLICITÉ",
    paragraph:
      "Pour vous simplifier la vie et que vous soyez pleinement concentré(e) sur votre travail, on a pensé à un service de coworking de proximité, simple à utiliser.",
    bgImage:
      "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80",
    cta: {
      ctaTitle: "je cherche un espace",
      ctaLink: "#search",
    },
  };
  return (
    <React.Fragment>
      <Hero data={heroContent} />
      <Search data={data} />
      <Footer />
    </React.Fragment>
  );
}
export const query = graphql`
  {
    allContentfulTheOfficeSpace {
      nodes {
        spaceCoverPicture {
          file {
            url
          }
        }
        spaceDescription {
          spaceDescription
        }
        spaceName
        prix
        slug
        stateAddress
      }
    }
  }
`;
